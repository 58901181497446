<template>
  <applicant-proposal-choose
    :applicant="applicant"
    :candidate-name="candidate.name"
    :candidate-source="candidate.source"
    @success="handleSuccess"
  />
</template>

<script>

import ApplicantProposalChoose from '@/pages/ApplicantProposal/components/ApplicantProposalChoose'
import { APPLICANTS } from '@/store/namespaces'
import { createNamespacedHelpers } from 'vuex'
import applicants from '@/repository/applicants.js'

const applicantsHelper = createNamespacedHelpers(APPLICANTS)

export default {
  name: 'ApplicantModalProposal',
  components: { ApplicantProposalChoose },
  computed: {
    ...applicantsHelper.mapState([
      'applicant']),
    candidate () {
      return this.applicant?.candidate
    }
  },
  methods: {
    ...applicantsHelper.mapActions(['removeApplicantWithConductedInterviews']),
    ...applicantsHelper.mapActions(['addApplicantWithSentProposal']),
    handleSuccess (event) {
      this.removeApplicantWithConductedInterviews(this.applicant?.id)
      if (event === 'proposal') this.addApplicantWithSentProposal(this.applicant)
      this.$router.push({ name: 'NewHome' })
      applicants.moveApplicantKind({
        applicantId: this.applicant?.id,
        stageKind: event === 'formalize' ? 'hired' : 'offer'
      })
    }
  }
}
</script>
